import React from "react"
import logo from "./lun-logo.webp"
import styles from "./lun-button.module.scss"

const LunButton = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className={styles.lunLink}>
      <span>{"Ми на "}</span>
      <img src={logo} alt="логотип ЛУН" className={styles.lunLogo} />
    </a>
  )
}

export default LunButton
