import React, { useMemo } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { useHousesList, useSiteMetadata } from "../hooks"
import { HousesGoogleMap } from "../components/map"
import "../styles/common/houses-list.scss"
import LunButton from "../components/lun/lun-button"
import { calcCityHouses } from "../utils"
import CityHousesSwiper from "../components/swiper/city-houses-swiper"
import { TelegramBanner } from "../components/banner"

const AboutPage = ({ location }) => {
  const houses = useHousesList(1000)

  const cityHouses = useMemo(() => calcCityHouses(houses), [houses])
  const statuses = useMemo(() => [...new Set(houses.map(h => h.status.name))], [
    houses,
  ])
  const cities = useMemo(() => [...new Set(houses.map(h => h.city.name))], [
    houses,
  ])

  const title = "Про нас"
  const description = `${statuses.join(", ")}. ${cities.join(", ")}`

  const {
    contacts: { lun },
  } = useSiteMetadata()

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      location={location}
    >
      <h1>{title}</h1>
      <p>
        Ми - компанія забудовник. Займаємося будівництвом приватних будинків з
        2010 року.
      </p>
      <p>
        На нашому рахунку понад 200 будинків Premium сегменту, збудованих як на
        продаж, так і під замовлення наших клієнтів.
      </p>
      <p>
        Наш ключовий девіз: <b>"Наші знання - ваша власність!"</b> У будівництво
        об'єктів ми вкладаємо всі свої знання та досвід.
      </p>
      <p>
        Будинки ми реалізуємо під чистове оздоблення або із сучасним
        дизайнерським ремонтом.
      </p>
      <p>
        Завчасно домовившись, готові виконати замовлення за вашим планом або
        внести зміни перед етапом закладання фундаменту.
      </p>
      <p>
        Будуємо котеджі з сучасним, комфортним плануванням, що створює ідеальну
        ергономіку життєвого простору, використовуючи екологічно чисті, якісні
        матеріали.
      </p>
      <p>
        У нас на сайті Ви можете ознайомитися з повним переліком будинків, які є
        у продажі або замовити будівництво "під ключ".
      </p>
      <p>
        Не зволікайте, телефонуйте на Вас чекає будинок мрії!{" "}
        <Link to="/contact">Наші контакти</Link>
      </p>
      <br />
      <LunButton url={lun} />
      <h3>Будинки в продажі:</h3>
      <CityHousesSwiper cities={cityHouses} />
      <TelegramBanner />
      <h3>Карта об'єктів:</h3>
      <HousesGoogleMap houses={houses} />
    </Layout>
  )
}

export default AboutPage
